// import React, { useState } from 'react'

// function CircuitCard({ circuit, setSelectedCircuit }) {
//     const [showList, setShowList] = useState(false);
//     let totalPrice = 0
//     const toggleList = () => {
//         setShowList(!showList);
//     };

//     const handleSetCircuit = () => {
//         setSelectedCircuit()
//     }

//     return (
//         <div className="circuitCard" >
//             <div className="leftCircuitCard">
//                 <div className='leftCircuitCardTop'>
//                     <button onClick={handleSetCircuit}>
//                         <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
//                             <g id="Groupe_558" data-name="Groupe 558" transform="translate(9.002 7.385)">
//                                 <g id="Ellipse_260" data-name="Ellipse 260" transform="translate(-9.002 -7.385)" fill="none" stroke="#aa0102" strokeWidth="1">
//                                     <circle cx="16" cy="16" r="16" stroke="none" />
//                                     <circle cx="16" cy="16" r="15.5" fill="none" />
//                                 </g>
//                                 <path id="Tracé_1180" data-name="Tracé 1180" d="M58.594,105.134a21.8,21.8,0,0,1-2.052-.952c-.81-.385-.924-1.261-.974-2.072a7.4,7.4,0,0,0,.021,2.572,1.944,1.944,0,0,0,.835,1.367.372.372,0,0,1,.139.257c-.119,1.664.264,2,.736,3.481a1.382,1.382,0,0,1-.083.984c-.013.036-.028.074-.043.109h0a2.032,2.032,0,0,1-1.908,1.271,2.315,2.315,0,0,1-.434-.043c0-.025-.007-.051-.012-.076.017-.02.031-.04.048-.058-.418.487-.938,1.037-1.228,1.34a2.2,2.2,0,0,0,2.453.594c.015,0,.03-.012.045-.018l.054-.025a2.063,2.063,0,0,0,1.184-1.5v0c.012-.053.02-.109.026-.163a23.726,23.726,0,0,0,.114-2.943c1.066.469,2.393.535,3.044,1.6a6.985,6.985,0,0,0-.063-2.131c.061-1.514-.322-3.008-1.9-3.6Zm1.269,2.9a1.921,1.921,0,0,1,.094.2c-1.172-.53-2.719-.616-3.222-1.961.756.361,1.444.629,2.2.946a1.992,1.992,0,0,1,.929.819Z" transform="translate(-48.811 -93.46)" fill="#aa0102" />
//                                 <path id="Tracé_1181" data-name="Tracé 1181" d="M9.68,106.243a21.882,21.882,0,0,1-.2-2.253c-.073-.895.629-1.431,1.306-1.88a7.423,7.423,0,0,0-2.217,1.3A1.952,1.952,0,0,0,7.8,104.82a.379.379,0,0,1-.154.249c-1.5.728-1.6,1.228-2.648,2.379a1.383,1.383,0,0,1-.893.421c-.04.007-.078.012-.117.017h0a2.031,2.031,0,0,1-2.053-1.017,2.231,2.231,0,0,1-.18-.4l.061-.048.074.012c-.631-.119-1.367-.294-1.774-.393a2.2,2.2,0,0,0,.711,2.422l.038.03.048.035a2.068,2.068,0,0,0,1.888.277.006.006,0,0,0,0,0,1.492,1.492,0,0,0,.155-.059,23.749,23.749,0,0,0,2.606-1.373c.127,1.157.733,2.341.135,3.437a6.912,6.912,0,0,0,1.814-1.121c1.342-.7,2.443-1.783,2.162-3.443ZM7.805,108.79a1.8,1.8,0,0,1-.124.18c-.127-1.279-.827-2.663.087-3.772.064.835.177,1.565.281,2.377a1.982,1.982,0,0,1-.244,1.213Z" transform="translate(-4.029 -93.46)" fill="#aa0102" />
//                                 <path id="Tracé_1182" data-name="Tracé 1182" d="M8.042,66.44a21.794,21.794,0,0,1,1.85-1.3,2.172,2.172,0,0,1,2.281.191,7.427,7.427,0,0,0-2.238-1.268,1.949,1.949,0,0,0-1.6.04.379.379,0,0,1-.294-.008c-1.38-.934-1.865-.771-3.384-1.1a1.383,1.383,0,0,1-.81-.563c-.025-.03-.05-.061-.073-.092h0a2.032,2.032,0,0,1-.147-2.288,2.258,2.258,0,0,1,.254-.355l.071.03c.01.023.018.048.026.071-.213-.606-.429-1.33-.546-1.733a2.2,2.2,0,0,0-1.741,1.827.418.418,0,0,0-.007.048c0,.02,0,.04-.007.058a2.064,2.064,0,0,0,.7,1.773s0,0,0,0c.041.036.084.071.129.106a23.475,23.475,0,0,0,2.493,1.57c-.939.688-1.661,1.806-2.908,1.836a6.952,6.952,0,0,0,1.877,1.01c1.281.809,2.765,1.225,4.064.152ZM4.9,66.09c-.063,0-.127-.01-.218-.017,1.045-.749,1.892-2.047,3.31-1.811-.692.474-1.266.936-1.918,1.433a1.988,1.988,0,0,1-1.172.4Z" transform="translate(-5.417 -56.681)" fill="#aa0102" />
//                                 <path id="Tracé_1183" data-name="Tracé 1183" d="M36.754,38.632a21.8,21.8,0,0,1,2.052.952c.81.385.924,1.261.974,2.072a7.4,7.4,0,0,0-.021-2.572,1.944,1.944,0,0,0-.835-1.367.372.372,0,0,1-.139-.258c.119-1.664-.264-2-.736-3.481a1.382,1.382,0,0,1,.083-.984c.013-.036.028-.074.043-.109h0a2.032,2.032,0,0,1,1.908-1.271,2.317,2.317,0,0,1,.434.043c0,.025.007.051.012.076-.017.02-.031.04-.048.058.418-.487.938-1.037,1.228-1.34a2.2,2.2,0,0,0-2.453-.594c-.015,0-.03.012-.045.018l-.054.025a2.063,2.063,0,0,0-1.184,1.5v0c-.012.053-.02.109-.026.163a23.726,23.726,0,0,0-.114,2.943c-1.066-.469-2.393-.535-3.044-1.6a6.985,6.985,0,0,0,.063,2.131c-.061,1.514.322,3.008,1.9,3.6Zm-1.269-2.9a1.922,1.922,0,0,1-.094-.2c1.172.53,2.719.616,3.222,1.961-.756-.362-1.444-.629-2.2-.946a1.992,1.992,0,0,1-.929-.819Z" transform="translate(-33.023 -33.006)" fill="#aa0102" />
//                                 <path id="Tracé_1184" data-name="Tracé 1184" d="M66.434,53.984a21.878,21.878,0,0,1,.2,2.253c.073.895-.629,1.431-1.306,1.88a7.423,7.423,0,0,0,2.217-1.3,1.952,1.952,0,0,0,.766-1.406.379.379,0,0,1,.154-.249c1.5-.728,1.6-1.228,2.648-2.379a1.383,1.383,0,0,1,.893-.421c.04-.007.078-.012.117-.017h0a2.031,2.031,0,0,1,2.053,1.017,2.232,2.232,0,0,1,.18.4L74.3,53.8l-.074-.012c.631.119,1.367.294,1.774.393a2.2,2.2,0,0,0-.711-2.422l-.038-.03L75.2,51.7a2.068,2.068,0,0,0-1.888-.277.006.006,0,0,0,0,0,1.493,1.493,0,0,0-.155.059,23.748,23.748,0,0,0-2.606,1.373c-.127-1.157-.733-2.341-.135-3.437A6.912,6.912,0,0,0,68.6,50.541c-1.342.7-2.443,1.783-2.162,3.443Zm1.875-2.547a1.8,1.8,0,0,1,.124-.18c.127,1.279.827,2.663-.087,3.772-.064-.835-.177-1.565-.281-2.377a1.982,1.982,0,0,1,.244-1.213Z" transform="translate(-58.571 -49.467)" fill="#aa0102" />
//                                 <path id="Tracé_1185" data-name="Tracé 1185" d="M69.452,92.117a21.792,21.792,0,0,1-1.85,1.3,2.172,2.172,0,0,1-2.281-.191,7.428,7.428,0,0,0,2.238,1.268,1.949,1.949,0,0,0,1.6-.04.379.379,0,0,1,.294.008c1.38.934,1.865.771,3.384,1.1a1.383,1.383,0,0,1,.81.563c.025.03.05.061.073.092h0a2.032,2.032,0,0,1,.147,2.288,2.257,2.257,0,0,1-.254.355l-.071-.03c-.01-.023-.018-.048-.026-.071.213.606.429,1.33.546,1.733A2.2,2.2,0,0,0,75.8,98.67a.416.416,0,0,0,.007-.048c0-.02,0-.04.007-.058a2.063,2.063,0,0,0-.7-1.773s0,0,0,0c-.041-.036-.084-.071-.129-.106a23.475,23.475,0,0,0-2.493-1.57c.939-.688,1.661-1.806,2.908-1.836a6.952,6.952,0,0,0-1.877-1.01c-1.281-.809-2.765-1.225-4.064-.152Zm3.143.35c.063,0,.127.01.218.017-1.045.749-1.892,2.047-3.31,1.811.692-.474,1.266-.936,1.918-1.433a1.988,1.988,0,0,1,1.172-.4Z" transform="translate(-58.563 -84.577)" fill="#aa0102" />
//                             </g>
//                         </svg>
//                     </button>

//                     <div>
//                         <h3>
//                             {circuit.livreur.firstName}  {circuit.livreur.lastName}
//                         </h3>
//                         <p>
//                             {circuit.livreur.phone}
//                         </p>
//                     </div>
//                 </div>
//                 <div className='leftCircuitCardButtom'>
//                     <p className='cardCommandeNumber'>Commandes à livrer ({circuit.commande.length})</p>
//                     {showList && (<ul>
//                         {
//                             circuit.commande.length > 0 && circuit.commande.map((e, index) => (
//                                 <li key={index}>
//                                     {
//                                         <div className='commandesCirc' key={index}>
//                                             <h3>Commande({index + 1})</h3>
//                                             {
//                                                 e.plats.map((p, index) => (
//                                                     <div key={index}>
//                                                         <p className='namePlat'>{p.plat.name} x {p.quantity}</p>
//                                                         <p className='pricePlat'>{p.plat.price * p.quantity}</p>

//                                                     </div>
//                                                 ))
//                                             }

//                                         </div>

//                                     }

//                                     <p className='adresse'>{e.client.address.streetBuilding}, {e.client.address.state}, {e.client.address.country}</p>
//                                 </li>

//                             ))
//                         }

//                     </ul>)}
//                     <p className='cardCommandeStatus'>en attente</p>
//                 </div>
//             </div>
//             <div className="rightCircuitCard">
//                 <div className="rightCircuitCardTop">
//                     <button><svg xmlns="http://www.w3.org/2000/svg" width="24" height="21.333" viewBox="0 0 24 21.333">
//                         <path id="Icon_awesome-map-marked-alt" data-name="Icon awesome-map-marked-alt" d="M12,0A5.25,5.25,0,0,0,6.75,5.25c0,2.344,3.431,6.617,4.746,8.168a.657.657,0,0,0,1.008,0c1.315-1.551,4.746-5.823,4.746-8.168A5.25,5.25,0,0,0,12,0Zm0,7a1.75,1.75,0,1,1,1.75-1.75A1.75,1.75,0,0,1,12,7ZM.838,9A1.334,1.334,0,0,0,0,10.236v10.43a.667.667,0,0,0,.914.619l5.752-2.618V8.955a12.618,12.618,0,0,1-.885-1.934ZM12,14.986a1.991,1.991,0,0,1-1.521-.707c-.819-.967-1.69-2.068-2.479-3.2v7.583l8,2.667V11.083c-.788,1.129-1.659,2.23-2.479,3.2A1.992,1.992,0,0,1,12,14.986ZM23.086,6.715,17.333,9.333v12L23.162,19A1.333,1.333,0,0,0,24,17.764V7.334A.667.667,0,0,0,23.086,6.715Z" fill="#b6b7b7" />
//                     </svg>
//                     </button>
//                 </div>
//                 <div className="rightCircuitCardButtom">
//                     {
//                         circuit.commande.length > 0 &&
//                         circuit.commande.map((e) =>
//                             e.plats.map((p) => {
//                                 // Ajouter le montant du plat actuel à totalPrice
//                                 totalPrice = totalPrice + p.plat.price * p.quantity;
//                                 return null; // Nécessaire car une expression JSX doit retourner quelque chose
//                             })
//                         )
//                     }

//                     {/* Afficher le montant total */}
//                     <p> {totalPrice}</p>
//                     <button onClick={toggleList}>
//                         {showList ? 'Voir moins -' : 'Voir plus +'}
//                     </button>
//                 </div>
//             </div>
//         </div >
//     )
// }

// export default CircuitCard

import React, { useState, useEffect } from 'react';

function CircuitCard({ circuit, setSelectedCircuit }) {
  const [showList, setShowList] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const toggleList = () => {
    setShowList((prevShowList) => !prevShowList);
  };

  const handleSetCircuit = () => {
    setSelectedCircuit();
  };

  // Calcul du montant total au rendu du composant
  useEffect(() => {
    let calculatedTotalPrice = 0;

    circuit.commande.forEach((e) => {
      e.plats.forEach((p) => {
        calculatedTotalPrice += p.plat.price * p.quantity;
      });
    });

    setTotalPrice(calculatedTotalPrice);
  }, [circuit.commande]);

  return (
    <div className="circuitCard">
      <div className="leftCircuitCard">
        <div className='leftCircuitCardTop'>
          <button >
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                            <g id="Groupe_558" data-name="Groupe 558" transform="translate(9.002 7.385)">
                                <g id="Ellipse_260" data-name="Ellipse 260" transform="translate(-9.002 -7.385)" fill="none" stroke="#aa0102" strokeWidth="1">
                                    <circle cx="16" cy="16" r="16" stroke="none" />
                                    <circle cx="16" cy="16" r="15.5" fill="none" />
                                </g>
                                <path id="Tracé_1180" data-name="Tracé 1180" d="M58.594,105.134a21.8,21.8,0,0,1-2.052-.952c-.81-.385-.924-1.261-.974-2.072a7.4,7.4,0,0,0,.021,2.572,1.944,1.944,0,0,0,.835,1.367.372.372,0,0,1,.139.257c-.119,1.664.264,2,.736,3.481a1.382,1.382,0,0,1-.083.984c-.013.036-.028.074-.043.109h0a2.032,2.032,0,0,1-1.908,1.271,2.315,2.315,0,0,1-.434-.043c0-.025-.007-.051-.012-.076.017-.02.031-.04.048-.058-.418.487-.938,1.037-1.228,1.34a2.2,2.2,0,0,0,2.453.594c.015,0,.03-.012.045-.018l.054-.025a2.063,2.063,0,0,0,1.184-1.5v0c.012-.053.02-.109.026-.163a23.726,23.726,0,0,0,.114-2.943c1.066.469,2.393.535,3.044,1.6a6.985,6.985,0,0,0-.063-2.131c.061-1.514-.322-3.008-1.9-3.6Zm1.269,2.9a1.921,1.921,0,0,1,.094.2c-1.172-.53-2.719-.616-3.222-1.961.756.361,1.444.629,2.2.946a1.992,1.992,0,0,1,.929.819Z" transform="translate(-48.811 -93.46)" fill="#aa0102" />
                                <path id="Tracé_1181" data-name="Tracé 1181" d="M9.68,106.243a21.882,21.882,0,0,1-.2-2.253c-.073-.895.629-1.431,1.306-1.88a7.423,7.423,0,0,0-2.217,1.3A1.952,1.952,0,0,0,7.8,104.82a.379.379,0,0,1-.154.249c-1.5.728-1.6,1.228-2.648,2.379a1.383,1.383,0,0,1-.893.421c-.04.007-.078.012-.117.017h0a2.031,2.031,0,0,1-2.053-1.017,2.231,2.231,0,0,1-.18-.4l.061-.048.074.012c-.631-.119-1.367-.294-1.774-.393a2.2,2.2,0,0,0,.711,2.422l.038.03.048.035a2.068,2.068,0,0,0,1.888.277.006.006,0,0,0,0,0,1.492,1.492,0,0,0,.155-.059,23.749,23.749,0,0,0,2.606-1.373c.127,1.157.733,2.341.135,3.437a6.912,6.912,0,0,0,1.814-1.121c1.342-.7,2.443-1.783,2.162-3.443ZM7.805,108.79a1.8,1.8,0,0,1-.124.18c-.127-1.279-.827-2.663.087-3.772.064.835.177,1.565.281,2.377a1.982,1.982,0,0,1-.244,1.213Z" transform="translate(-4.029 -93.46)" fill="#aa0102" />
                                <path id="Tracé_1182" data-name="Tracé 1182" d="M8.042,66.44a21.794,21.794,0,0,1,1.85-1.3,2.172,2.172,0,0,1,2.281.191,7.427,7.427,0,0,0-2.238-1.268,1.949,1.949,0,0,0-1.6.04.379.379,0,0,1-.294-.008c-1.38-.934-1.865-.771-3.384-1.1a1.383,1.383,0,0,1-.81-.563c-.025-.03-.05-.061-.073-.092h0a2.032,2.032,0,0,1-.147-2.288,2.258,2.258,0,0,1,.254-.355l.071.03c.01.023.018.048.026.071-.213-.606-.429-1.33-.546-1.733a2.2,2.2,0,0,0-1.741,1.827.418.418,0,0,0-.007.048c0,.02,0,.04-.007.058a2.064,2.064,0,0,0,.7,1.773s0,0,0,0c.041.036.084.071.129.106a23.475,23.475,0,0,0,2.493,1.57c-.939.688-1.661,1.806-2.908,1.836a6.952,6.952,0,0,0,1.877,1.01c1.281.809,2.765,1.225,4.064.152ZM4.9,66.09c-.063,0-.127-.01-.218-.017,1.045-.749,1.892-2.047,3.31-1.811-.692.474-1.266.936-1.918,1.433a1.988,1.988,0,0,1-1.172.4Z" transform="translate(-5.417 -56.681)" fill="#aa0102" />
                                <path id="Tracé_1183" data-name="Tracé 1183" d="M36.754,38.632a21.8,21.8,0,0,1,2.052.952c.81.385.924,1.261.974,2.072a7.4,7.4,0,0,0-.021-2.572,1.944,1.944,0,0,0-.835-1.367.372.372,0,0,1-.139-.258c.119-1.664-.264-2-.736-3.481a1.382,1.382,0,0,1,.083-.984c.013-.036.028-.074.043-.109h0a2.032,2.032,0,0,1,1.908-1.271,2.317,2.317,0,0,1,.434.043c0,.025.007.051.012.076-.017.02-.031.04-.048.058.418-.487.938-1.037,1.228-1.34a2.2,2.2,0,0,0-2.453-.594c-.015,0-.03.012-.045.018l-.054.025a2.063,2.063,0,0,0-1.184,1.5v0c-.012.053-.02.109-.026.163a23.726,23.726,0,0,0-.114,2.943c-1.066-.469-2.393-.535-3.044-1.6a6.985,6.985,0,0,0,.063,2.131c-.061,1.514.322,3.008,1.9,3.6Zm-1.269-2.9a1.922,1.922,0,0,1-.094-.2c1.172.53,2.719.616,3.222,1.961-.756-.362-1.444-.629-2.2-.946a1.992,1.992,0,0,1-.929-.819Z" transform="translate(-33.023 -33.006)" fill="#aa0102" />
                                <path id="Tracé_1184" data-name="Tracé 1184" d="M66.434,53.984a21.878,21.878,0,0,1,.2,2.253c.073.895-.629,1.431-1.306,1.88a7.423,7.423,0,0,0,2.217-1.3,1.952,1.952,0,0,0,.766-1.406.379.379,0,0,1,.154-.249c1.5-.728,1.6-1.228,2.648-2.379a1.383,1.383,0,0,1,.893-.421c.04-.007.078-.012.117-.017h0a2.031,2.031,0,0,1,2.053,1.017,2.232,2.232,0,0,1,.18.4L74.3,53.8l-.074-.012c.631.119,1.367.294,1.774.393a2.2,2.2,0,0,0-.711-2.422l-.038-.03L75.2,51.7a2.068,2.068,0,0,0-1.888-.277.006.006,0,0,0,0,0,1.493,1.493,0,0,0-.155.059,23.748,23.748,0,0,0-2.606,1.373c-.127-1.157-.733-2.341-.135-3.437A6.912,6.912,0,0,0,68.6,50.541c-1.342.7-2.443,1.783-2.162,3.443Zm1.875-2.547a1.8,1.8,0,0,1,.124-.18c.127,1.279.827,2.663-.087,3.772-.064-.835-.177-1.565-.281-2.377a1.982,1.982,0,0,1,.244-1.213Z" transform="translate(-58.571 -49.467)" fill="#aa0102" />
                                <path id="Tracé_1185" data-name="Tracé 1185" d="M69.452,92.117a21.792,21.792,0,0,1-1.85,1.3,2.172,2.172,0,0,1-2.281-.191,7.428,7.428,0,0,0,2.238,1.268,1.949,1.949,0,0,0,1.6-.04.379.379,0,0,1,.294.008c1.38.934,1.865.771,3.384,1.1a1.383,1.383,0,0,1,.81.563c.025.03.05.061.073.092h0a2.032,2.032,0,0,1,.147,2.288,2.257,2.257,0,0,1-.254.355l-.071-.03c-.01-.023-.018-.048-.026-.071.213.606.429,1.33.546,1.733A2.2,2.2,0,0,0,75.8,98.67a.416.416,0,0,0,.007-.048c0-.02,0-.04.007-.058a2.063,2.063,0,0,0-.7-1.773s0,0,0,0c-.041-.036-.084-.071-.129-.106a23.475,23.475,0,0,0-2.493-1.57c.939-.688,1.661-1.806,2.908-1.836a6.952,6.952,0,0,0-1.877-1.01c-1.281-.809-2.765-1.225-4.064-.152Zm3.143.35c.063,0,.127.01.218.017-1.045.749-1.892,2.047-3.31,1.811.692-.474,1.266-.936,1.918-1.433a1.988,1.988,0,0,1,1.172-.4Z" transform="translate(-58.563 -84.577)" fill="#aa0102" />
                            </g>
                        </svg>
          </button>

          <div>
            <h3>
              {circuit.livreur.firstName} {circuit.livreur.lastName}
            </h3>
            <p>
              {circuit.livreur.phone}
            </p>
          </div>
        </div>
        <div className='leftCircuitCardButtom'>
          <p className='cardCommandeNumber'>Commandes à livrer ({circuit.commande.length})</p>
          {showList && (<ul>
            {
              circuit.commande.length > 0 && circuit.commande.map((e, index) => (
                <li key={index}>
                  {
                    <div className='commandesCirc' key={index}>
                      <h3>Commande({index + 1})</h3>
                      {
                        e.plats.map((p, index) => (
                          <div key={index}>
                            <p className='namePlat'>{p.plat.name} x {p.quantity}</p>
                            <p className='pricePlat'>{p.plat.price * p.quantity}</p>
                          </div>
                        ))
                      }
                    </div>
                  }
                  <p className='adresse'>{e.client.address.streetBuilding}, {e.client.address.state}, {e.client.address.country}</p>
                </li>
              ))
            }
          </ul>)}
          <p className='cardCommandeStatus'>en attente</p>
        </div>
      </div>
      <div className="rightCircuitCard">
        <div className="rightCircuitCardTop">
          <button onClick={handleSetCircuit}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21.333" viewBox="0 0 24 21.333">
                        <path id="Icon_awesome-map-marked-alt" data-name="Icon awesome-map-marked-alt" d="M12,0A5.25,5.25,0,0,0,6.75,5.25c0,2.344,3.431,6.617,4.746,8.168a.657.657,0,0,0,1.008,0c1.315-1.551,4.746-5.823,4.746-8.168A5.25,5.25,0,0,0,12,0Zm0,7a1.75,1.75,0,1,1,1.75-1.75A1.75,1.75,0,0,1,12,7ZM.838,9A1.334,1.334,0,0,0,0,10.236v10.43a.667.667,0,0,0,.914.619l5.752-2.618V8.955a12.618,12.618,0,0,1-.885-1.934ZM12,14.986a1.991,1.991,0,0,1-1.521-.707c-.819-.967-1.69-2.068-2.479-3.2v7.583l8,2.667V11.083c-.788,1.129-1.659,2.23-2.479,3.2A1.992,1.992,0,0,1,12,14.986ZM23.086,6.715,17.333,9.333v12L23.162,19A1.333,1.333,0,0,0,24,17.764V7.334A.667.667,0,0,0,23.086,6.715Z" fill="#b6b7b7" />
                    </svg>
          </button>
        </div>
        <div className="rightCircuitCardButtom">
          <p> {totalPrice}</p>
          <button onClick={toggleList}>
            {showList ? 'Voir moins -' : 'Voir plus +'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CircuitCard;
